import './img/super-saturday-vaxathon-logo.svg';
import './css/main.scss';
import { fetch } from 'whatwg-fetch';

const ENDPOINT = 'https://api.vaxathon.nz';
const form = document.getElementById('competitionForm');
const error = document.getElementById('competitionFormError');
const button = document.getElementById('competitionFormSubmitButton');

if (form) {
  form.addEventListener('submit', e => {
    e.preventDefault();
    e.stopPropagation();

    const formData = {
      firstName: document.getElementById('txt_first_name').value,
      lastName: document.getElementById('txt_last_name').value,
      dateOfBirth: document.getElementById('txt_dob').value,
      emailAddress: document.getElementById('txt_email').value,
      contactPhoneNumber: document.getElementById('txt_tel').value,
      numDoses: document.getElementById('rdo_dose_count_1').checked
        ? 1
        : document.getElementById('rdo_dose_count_2').checked
        ? 2
        : -1,
      whyVaccinated: document.getElementById('txt_why_vaccinated').value,
      termsConditionsAccepted: document.getElementById('chk_terms').checked
    };

    const body = JSON.stringify(formData);

    button.disabled = true;

    fetch(ENDPOINT, {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }).finally(() => {
      form.reset();
      window.location.pathname = '/thanks.html';
      button.disabled = false;
    });
  });
}
